.text_descriptor_name{
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 100px;   
}

.text_descriptor_note{
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px  
}

.akwaba-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;    
    min-height:250px;
    background: url("../../clientapp/img/computer-algorithm.jpg");      
  }
  
  .akwaba-content{ 
  width:100%;
  }

  .akwaba-content-training{ 
    width:100%;
    height: 250px;
    background-color: aqua;
    }

    .akwaba-content-admin{ 
      width:100%;     
      background-color: goldenrod;
      }
  
  .akwaba-lang {
    padding: 10px 16px;    
    top: 0;
    width: 100%;    
    background: -webkit-linear-gradient(#eee, #808080); 
  }
  
  .akwaba-container-items {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
  }
  
  
  .akwaba-container-items-adminpage {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
    background-color: white;
    width: 100%;
    list-style-type:none;
  }
  
  .akwaba-tiles-navlinks {
    color: white;
    background-color: #808080;
    font-family: verdana;
    font-size: 16px;
    font-weight: bold;
    width: 50%;
  }
  .akwaba-services-title {
    padding: 10px 10x 10px 10px;
    font-family: verdana;
    font-size: 26px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .akwaba-tiles {
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius:  5px;
    border: 5px;  
  }
  
  .akwaba-group-tiles{
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius: 5px;
    border:  5px;
    border-color: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  #signedin{
    color:magenta;
  }

  .loadmore{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: navy;
    border-radius: 5px;
    border:  5px;
    border-color: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reply-link-homepage{
    margin-top: 1rem;
    margin-bottom: 1rem;   
    color: red;
    background-color: white;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    border-radius: 10px;
    text-align: center;    
    justify-content: center;
    text-decoration:none;
  }

  .home-pic-align{
    background-color: aliceblue;
    width:max-content;
    justify-content: center;
    align-content: center;
    display: flex;
    
  }
  
.margin-maker{
  margin-left: 20px;
}

  .admin-home{
    color: #fff;
    background-color: grey;
    font-family: verdana;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .admin-link-to-post{ 
    background-color:white;
    border: solid #e31b50 4px;
    border-radius: 15px;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding: 10 10 10 10;
}

.case-form{
  border: solid cyan 1px;
   border-radius: 10px; 
   color: blue;
    background-color: cyan;
}

button.home-search-button{
  border-radius: 10px; 
   color: indigo;
   background-color: orange;
}

.input[type="text"]:enabled {
  background: khaki;
}

.input:invalid {
  border: 2px solid red;
}

.input:focus {
  background-color: cyan;
}

::-webkit-input-placeholder { /* Edge */
  color:  goldenrod;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  goldenrod;
}

::placeholder {
  color: goldenrod;
}

img.detail-page-carousel-image{ 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  max-width: 400px;
  height: auto;
}

img.home-page-image{ 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 400px;
  max-height:400px;
}

@media only screen and (max-width: 600px) and (min-width: 400px)  {


}