  .header-akwaba {
    min-width:210px;
    bottom:0px;
    margin-bottom: 0px;
    padding-bottom: 0px; 
    font-weight: 700;
    font-size: 24px;
    color:#f1f1f1;  
    background-image: linear-gradient( #808080, white);
  
  }
  
  .header-akwaba-ul{
    color: whitesmoke;
    
    }

  #header-akwaba-tiles-navlinks-home{
      color:black;  
      margin: 0;
      margin-top:40px;
      padding: 10px 0 0 0;
      list-style: none;  
      background: #111;
      background: -moz-linear-gradient(#444, #111); 
         
      background: -webkit-linear-gradient(#444, #111);	
      background: -o-linear-gradient(#444, #111);
      background: -ms-linear-gradient(#444, #111);
      background: linear-gradient(#444, #111);
      -moz-border-radius: 50px;
      border-radius: 50px;
      -moz-box-shadow: 0 2px 1px #9c9c9c;
      -webkit-box-shadow: 0 2px 1px #9c9c9c;
      box-shadow: 0 2px 1px #9c9c9c;
    }
    /*nav bar text(our partners, our academy, our services, register, login, logout) */
    .header-akwaba-rightnavbar-navlinks {
      color: grey;
      font-family: verdana;
      font-size: 14px;
      font-weight:normal;
      width: 100%;
      z-index:1
    }
    
    
/***    Navs ********************/
  .no-bullets-nav {
    width: 100%;
  }
  
  ul.no-bullets-nav {
    background-color:  #808080;
   background-image: url("../../clientapp/img/computer-algorithm.jpg");
    min-height: 6vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    list-style-type: none; /* Remove bullets */
    padding: 5px; /* Remove padding */
    margin: 0; /* Remove margins */
    text-align: center;
  }
  
  ul.no-bullets-nav li {
    display: inline;
    margin-inline: 10px;
    padding: 10px;
  }
  
  ul.no-bullets-nav li a {
    color: navy;
    font-weight: bold;
    text-decoration: none;
  }
  
  /* visited link */
  ul.no-bullets-nav li a:visited {
    color: #66c0fc;
  }
  
  /* mouse over link */
  ul.no-bullets-nav li a:hover {
    color: #0ca131;
  }
  
  /* selected link */
  ul.no-bullets-nav li a:active {
    color: #f7f707;
  }
  
  .no-bullets-nav-palin {
    width: 100%;
  }
  
  ul.no-bullets-nav-palin {
    background-color: #808080;
    min-height: 6vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    list-style-type: none; /* Remove bullets */
    padding: 5px; /* Remove padding */
    margin: 0; /* Remove margins */
    text-align: center;
  }
  
  ul.no-bullets-nav-palin li {
    display: inline;
    margin-inline: 6px;
    padding: 6px;
    font-weight: bold;
    font-size: 18px;   
  }
  
  ul.no-bullets-nav-palin li a {
    color: white; 
    font-weight: bold;
    text-decoration: none;
  }
  
  /* visited link */
  ul.no-bullets-nav-palin li a:visited {
    color: yellow;
  }
  
  /* mouse over link */
  ul.no-bullets-nav-palin li a:hover {
    color: red;
  }
  
  /* selected link */
  ul.no-bullets-nav-palin li a:active {
    color: yellow;
  }

.header-collapse {
    cursor: pointer;
    border: solid 1px #f2f2f2;
    padding: 15px;
    background-color: #808080;
    color: #fff;
    font-family: verdana;
  }

/* Menus*/
#menu{
	margin: 0;
  /* margin-top: 40px; */
	padding: 10px 0 0 0;
	list-style: none;     
  /* background-image: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet);  */
	 /*background: #111;
   	background-image: linear-gradient(#444, #111);
 */
	background: -moz-linear-gradient(#444, #111); 
     
	background: -webkit-linear-gradient(#444, #111);	
	background: -o-linear-gradient(#444, #111);
	background: -ms-linear-gradient(#444, #111); 

	/* -moz-border-radius: 50px; */
	/* border-radius: 50px; */
	/* -moz-box-shadow: 0 2px 1px #9c9c9c; */
	/* -webkit-box-shadow: 0 2px 1px #9c9c9c; */
	/* box-shadow: 0 2px 1px #9c9c9c; */
 
} 

#menu li{
	float: left;
	padding: 0 0 10px 0;
	position: relative;
}


#menu a{
	float: left;
	height: 25px;
	padding: 0 25px;
	color: #0497f3;
	text-transform: uppercase;
	font: bold 18px/25px Arial, Helvetica;
	text-decoration: none;
	text-shadow: 0 1px 0 #000;
}

#menu li:hover > a{
	color: #fafafa;
}

*html #menu li a:hover{ /* IE6 */
	color: #fafafa;
}

#menu li:hover > ul{
	display: block;
}

/* Sub-menu */

#menu ul{
    list-style: none;
    margin: 0;
    padding: 0;    
    display: none;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 99999;    
    background: #444;
    background: -moz-linear-gradient(#444, #111);  
    background: -webkit-linear-gradient(#444, #111);    
    background: -o-linear-gradient(#444, #111);	
    background: -ms-linear-gradient(#444, #111);	
    background: linear-gradient(#444, #111);	
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#menu ul li{
    float: none;
    margin: 0;
    padding: 0;
    display: block;  
    -moz-box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
    -webkit-box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
    box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
}

#menu ul li:last-child{   
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;    
}

#menu ul a{    
    padding: 10px;
    height: auto;
    line-height: 1;
    display: block;
    white-space: nowrap;
    float: none;
    text-transform: none;
}

*html #menu ul a{ /* IE6 */   
	height: 10px;
	width: 150px;
}

*:first-child+html #menu ul a{ /* IE7 */    
	height: 10px;
	width: 150px;
}

#menu ul a:hover{
        background: #808080;
	background: -moz-linear-gradient(#04acec,  #0186ba);	
	/* background: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba));
	background: -webkit-linear-gradient(#04acec,  #0186ba);
	background: -o-linear-gradient(#04acec,  #0186ba);
	background: -ms-linear-gradient(#04acec,  #0186ba);
	background: linear-gradient(#04acec,  #0186ba); */
}

#menu ul li:first-child a{
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

#menu ul li:first-child a:after{
    content: '';
    position: absolute;
    left: 30px;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #444;
}

#menu ul li:first-child a:hover:after{
    border-bottom-color:#808080;; 
}

#menu ul li:last-child a{
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}

/* Clear floated elements */
#menu:after{
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

* html #menu             { zoom: 1; } /* IE6 */
*:first-child+html #menu { zoom: 1; } /* IE7 */

