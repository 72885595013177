.carousel-text{
    position: absolute;
    top: 8px;
    left: 600px;
    color: white;
    text-align: left;
    /* font-size: 36px;
    font-weight: 600px; */
    /* background-image: linear-gradient(to right,blue,indigo,violet);	 */
    background: linear-gradient(transparent, gray);
    border-radius: 8px;
  }


  .car_carousel{    
    align-items: left;
    justify-content: center;
    align-self:center;
    position:relative;
    border-radius: 5px;
    border:  5px;
    height: auto; 
    width: auto; 
    max-width: 1000px; 
    max-height: 700px;
  
    image-rendering: high-quality;
  }
  