
 .footer {
    background-color: #cfb53b;
    border-radius: 3px;
    align-content: center;
    text-align: center;
    height: 100px;
    color: #ffffff;
  }
  
  .footer-akwaba {
    flex-direction: column;
    padding: 10px 20px;
    text-align: center;
    color: white;
    width: 100%;
    min-width:210px;
    background-color: #808080;;
    height: 500px;
  }
  
  .footer-akwaba-items {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px; 
    color: white;
    width: 100%;
  }